import React, { useState, useEffect } from "react";
import PostBox from "./PostBox/PostBox";
import "./Posts.css";
import { get_posts } from "../../api/Api";
import Loader from "../loader/loader";
import PostsJSON from "../../data/Posts.json";

const Posts = () => {
  const [postList, changePostList] = useState([]);
  const [loading, changeLoading] = useState(true);
  const [apiUse, changeApiUse] = useState(false);

  const loadJSON = () => {
    changePostList(PostsJSON.posts);
    changeLoading(false);
  };

  useEffect(() => {
    apiUse
      ? get_posts().then((res) => {
          changePostList(res);
          changeLoading(false);
        })
      : loadJSON();
  }, []);

  return (
    <div className="posts">
      {/* <div className="topHeader">
        <h1>Recent Posts</h1>
      </div> */}
      {loading ? <Loader /> : null}
      <div className="postList">
        {postList.map((post) => (
          <PostBox
            key={post.post_id}
            title={post.post_title}
            description={post.post_description}
            snippet={post.post_snippet}
            id={post.post_id}
            date={post.published_date}
          />
        ))}
      </div>
    </div>
  );
};

export default Posts;
