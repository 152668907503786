import React from "react";
import AboutBox from "../AboutBox/AboutBox";
import "./Portfolio.css";
import PortfolioData from "../../data/Portfolio.json";
import PortfolioItem from "../../PortfolioItem/PortfolioItem";
import QuickLinks from "../QuickLinks/QuickLinks";

const Portfolio = () => {
  return (
    <div className="portfolio">
      <AboutBox />

      <div className="portfolio-content">
        <QuickLinks />
        <h1>My Work (so far...)</h1>
        <p>Working on hosting the projects.</p>
        <div className="portfolio-items">
          {PortfolioData.portfolios.map((item, index) => (
            <div className="portfolio-item-wrapper" key={index}>
              <PortfolioItem
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                date={item.date}
                liveDemoLink={item.liveDemoLink}
                sourceCodeLink={item.sourceCodeLink}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
