import React, { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./QuickLinks.css";
import tags from "../../data/Tags.json";

const QuickLinks = ({ tag }) => {
  const links = [
    {
      title: "Home",
      link: "/",
    },
  ];
  return (
    <div className="quickLinks">
      <>Quick Links: </>
      {links.map((linkItem) => (
        <Link className="link-item" to={linkItem.link}>
          {linkItem.title}
        </Link>
      ))}
    </div>
  );
};

export default QuickLinks;
