import React from "react";
import Posts from "../../components/Posts/Posts";
import { Helmet } from "react-helmet";
import AboutBox from "../../components/AboutBox/AboutBox";
import "./Home.css";
import ThreadHeader from "../../components/TagsHeader/TagsHeader";

const Home = () => {
  return (
    <>
      <div className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sulav Sapkota</title>
          <meta
            name="Personal Blog"
            content="Author: Sulav Sapkota | Running Since: 2023"
          />
        </Helmet>
        <AboutBox />
        <ThreadHeader />
        <Posts />
      </div>
    </>
  );
};

export default Home;
