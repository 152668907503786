import React, { useEffect, useState } from "react";
import "./TagsPostList.css";
import { useParams } from "react-router-dom";
import PostData from "../../data/Posts.json";
import PostBox from "../Posts/PostBox/PostBox";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import QuickLinks from "../QuickLinks/QuickLinks";
import tags from "../../data/Tags.json";

const TagsPostList = () => {
  const [taggedPosts, setTaggedPosts] = useState([]);
  let param = useParams();
  param.tagName = decodeURIComponent(param.tagName);
  let tag = tags.filter((t) => t.title === param.tagName)[0];
  console.log(tag);
  console.log(param);
  useEffect(() => {
    let posts = PostData.posts.filter((post) =>
      post.tags.includes(param.tagName)
    );
    setTaggedPosts(posts);
  }, []);
  return (
    <div className="tags-post-list">
      <h1>{param.tagName}</h1>
      {/* <p className="tag-description">{tag.description}</p> */}
      <QuickLinks />
      <div className="posts-links">
        {taggedPosts.length != 0 ? (
          taggedPosts.map((post) => (
            <div className="taggedPost">
              <Link
                className="tagged-title"
                to={`/post_description/${post.post_id}/${post.post_title}`}
              >
                <p>{post.post_title}</p>
              </Link>
            </div>
          ))
        ) : (
          <p>No posts yet...</p>
        )}
      </div>
    </div>
  );
};

export default TagsPostList;
