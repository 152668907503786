import axios from "axios";

const mainUrl = "https://sulavblogbackend.herokuapp.com";
//const mainUrl = "http://127.0.0.1:8000";

const endpoints = {
  getPosts: "posts",
  getPost: "post",
  addPost: "add_post",
  deletePost: "delete_post",
  login: "login",
  authenticate: "authenticate",
  sendMessage: "contact",
};

const get_posts = () => {
  return axios.get(`${mainUrl}/${endpoints.getPosts}`).then((res) => res.data);
};

const get_post = (post_id) => {
  return axios
    .get(`${mainUrl}/${endpoints.getPost}/${post_id}`)
    .then((res) => res.data);
};

const add_post = (post_obj) => {
  console.log(post_obj);
  return axios
    .post(`${mainUrl}/${endpoints.addPost}`, post_obj)
    .then((res) => console.log(res));
};
const delete_post = (post_id) => {
  return axios
    .delete(`${mainUrl}/${endpoints.deletePost}/${post_id}`)
    .then((res) => res.data);
};

const login_user = (credentials) => {
  return axios
    .post(`${mainUrl}/${endpoints.login}`, credentials)
    .then((res) => res.data);
};

const authenticate_user = (token) => {
  return axios
    .post(`${mainUrl}/${endpoints.authenticate}`, { token: token })
    .then((res) => res.data);
};

const sendMessage = (message) => {
  return axios
    .post(`${mainUrl}/${endpoints.sendMessage}`, message)
    .then((res) => res.data)
    .catch((err) => err);
};

export {
  get_posts,
  get_post,
  add_post,
  login_user,
  authenticate_user,
  delete_post,
  sendMessage,
};
