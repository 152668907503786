import React from "react";
import Box from "../../Box/Box";
import "./PostBox.css";
import { Link } from "react-router-dom";

const PostBox = (props) => {
  return (
    <div className="postBox">
      <Box>
        <Link
          className="title"
          to={`/post_description/${props.id}/${encodeURIComponent(
            props.title
          )}/`}
        >
          {" "}
          <h1>{props.title}</h1>
        </Link>

        <div className="icons">{props.date} &nbsp;</div>
        <p>{props.snippet}....</p>
        <Link to={`/post_description/${props.id}/${props.title}`}>
          <a> Continue Reading...</a>
        </Link>
      </Box>
    </div>
  );
};

export default PostBox;
