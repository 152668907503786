import React from "react";
import "./AboutBox.css";
import Box from "../Box/Box";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AboutBox = () => {
  return (
    <Box>
      {" "}
      <div className="aboutBox">
        <Link style={{ "text-decoration": "none" }} to="/">
          <h1>Sulav Sapkota</h1>
        </Link>
        <i>Versatile software virtuoso</i>
        <div className="links">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/sulavsapkota_"
          >
            <button className="primary">
              <i className="fa fa-twitter"></i> Twitter
            </button>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/SulavSapkota2060"
          >
            <button className="dark">
              <i className="fa fa-github"></i> Github
            </button>
          </a>
          {/* <a
          target="_blank"
          rel="noreferrer"
          href="https://instagram.com/sulavsapkota__"
        >
          <button className="pink">
            <i className="fa fa-instagram"></i> Instagram
          </button>
        </a> */}

          <Link to="/portfolio">
            <button className="shiny-button">
              <i className="fa fa-briefcase"></i> Portfolio
            </button>
          </Link>
        </div>
      </div>
    </Box>
  );
};

export default AboutBox;
