import React from "react";
import "./PortfolioItem.css";

const PortfolioItem = ({
  imageSrc,
  title,
  description,
  date,
  liveDemoLink,
  sourceCodeLink,
}) => {
  const handleImageHover = () => {
    // Handle image hover event (e.g., show options)
  };

  return (
    <div className="portfolio-item">
      <div className="image-container" onMouseEnter={handleImageHover}>
        <img src={imageSrc} alt="Portfolio Item" className="portfolio-image" />
        <div className="image-overlay">
          <div className="overlay-options">
            <a href={liveDemoLink} className="overlay-option">
              Live Demo
            </a>
            <a href={sourceCodeLink} className="overlay-option">
              Source Code
            </a>
          </div>
        </div>
      </div>
      <h3 className="portfolio-title">{title}</h3>
      <p className="portfolio-description">{description}</p>
      <span className="portfolio-date">{date}</span>
    </div>
  );
};

export default PortfolioItem;
