import React, { useEffect, useState } from "react";
import "./PostDescription.css";
import ReactMarkDown from "react-markdown";
import { get_post } from "../../../api/Api";
import { Link } from "react-router-dom";
import Loader from "../../loader/loader";
import PostsJSON from "../../../data/Posts.json";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import QuickLinks from "../../QuickLinks/QuickLinks";

const PostDescription = (props) => {
  const [post, changePost] = useState({});
  const [loading, changeLoading] = useState(true);
  const [apiUse, changeApiUse] = useState(false);
  const postId = useParams().id;

  useEffect(() => {
    if (apiUse === true) {
      get_post(postId).then((res) => {
        changePost(res);
        changeLoading(false);
      });
    } else {
      let fetchedPost = PostsJSON.posts.filter(
        (post) => post.post_id == postId
      )[0];

      const fetchMarkdown = async (id) => {
        console.log(id);
        try {
          const content = await import(`../../../data/Posts/${id}.md`);
          const file = await fetch(content.default);
          const text = await file.text();

          fetchedPost.post_description = text;
          changePost(fetchedPost);
        } catch (error) {
          console.log(error);
          return "# Something wet wrong!";
        }
      };

      fetchMarkdown(fetchedPost.post_id).then((res) => {
        changeLoading(false);
      });
    }
  }, [post]);
  return (
    <div className="postDescription">
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{post.post_title}</title>
            <meta name={post.post_title} content={post.post_description} />
          </Helmet>
          <h1 className="post-title">{post.post_title}</h1>
          <i>
            <i className="fa fa-user"></i> Sulav Sapkota
          </i>
          &nbsp; &nbsp;
          <i>
            <i className="fa fa-calendar"></i> {post.published_date}
          </i>
          <i>
            &nbsp; &nbsp;
            <Link to={`/tags/${encodeURIComponent(post.tags[0])}`}>
              {post.tags[0]}
            </Link>
          </i>
          &nbsp; &nbsp; <br />
          <div className="actions">
            <QuickLinks />
          </div>
          <div className="share"></div>
          <section className="description">
            <ReactMarkDown>{post.post_description}</ReactMarkDown>
          </section>
        </div>
      )}
    </div>
  );
};

export default PostDescription;
