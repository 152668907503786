import React, { useState } from "react";
import { sendMessage } from "../../api/Api";
import Alert from "../../components/Alerts/Alert";
import Loader from "../../components/loader/loader";

const Contact = () => {
  const [alert, changeAlert] = useState();
  const [loading, changeLoading] = useState(false);
  const send_message = () => {
    changeLoading(true);
    const message = {
      full_name: document.querySelector("#name").value,
      message: document.querySelector("#message").value,
    };
    sendMessage(message)
      .then((res) => {
        changeLoading(false);
        res.status === "success" ? (
          changeAlert(<Alert type="success">Successfully Sent Message!</Alert>)
        ) : (
          <Alert type="error">Couldn't send message :(</Alert>
        );
      })
      .catch((err) => {
        changeAlert(<Alert type="error">Couldn't send message :(</Alert>);
      });
  };
  return (
    <div className="contact container">
      <h1>Contact SULAV</h1>
      {alert}
      {loading ? <Loader /> : null}
      <p>Full Name</p>
      <input type="text" id="name" />
      <p>Message</p>
      <textarea
        name="message"
        style={{ height: "50vh" }}
        id="message"
        cols="30"
        rows="150"
      ></textarea>
      <br />
      <br />
      <button onClick={() => send_message()} className="primary">
        {" "}
        <i className="fa fa-send"></i> SEND
      </button>
    </div>
  );
};

export default Contact;
