import React from "react";
import "./TagsHeader.css";
import TagsData from "../../data/Tags.json";
import { Link } from "react-router-dom";

const TagsHeader = () => {
  return (
    <div className="tagsHeader">
      Tags:
      <div className="tags">
        {TagsData.map((item) => (
          <Link
            to={`/tags/${encodeURIComponent(item.title)}`}
            className="tags-title"
          >
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TagsHeader;
