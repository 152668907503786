import React, { useEffect } from "react";
import "./Alert.css";

const Alert = (props) => {
  const removeWarning = (e) => {
    document.querySelector(".alert").style.display = "none";
  };

  useEffect(() => {
    let alertBox = document.querySelector(".alert");
    if (alertBox.style.display === "none") {
      alertBox.style.display = "block";
    }
  });

  return (
    <div>
      {props.type === "success" ? (
        <div className="success alert">
          {props.children}
          <i
            style={{ float: "right" }}
            className="fa fa-times cross_icon"
            onClick={(e) => removeWarning(e)}
          ></i>
        </div>
      ) : (
        <div className="alert danger">
          {props.children}
          <i
            style={{ float: "right" }}
            className="fa fa-times cross_icon"
            onClick={(e) => removeWarning(e)}
          ></i>
        </div>
      )}
    </div>
  );
};

export default Alert;
