import React, { useState, useEffect } from "react";
import "./Layout.css";
import { BrowserRouter, Route } from "react-router-dom";
import PostDescription from "../components/Posts/PostDescription/PostDescription";
import Home from "../pages/Home/Home";
import Sidebar from "../components/sidebar/Sidebar";
import NewPost from "../pages/Admin/NewPost/NewPost";

// Admin Panel
import Dashboard from "../pages/Admin/Dashboard/Dashboard";
import Auth from "../pages/Admin/Auth/Auth";
import Contact from "../pages/Contact/contact";
import Portfolio from "../components/Portfolio/Portfolio";
import TagsPostList from "../components/TagsPostList/TagsPostList";

const Layout = () => {
  const [theme, changeTheme] = useState(window.localStorage.getItem("theme"));
  const [themeSwitcher, changeThemeSwitcher] = useState(
    <i style={{ color: "white" }} className="fa fa-sun-o"></i>
  );

  const switchTheme = () => {
    if (theme === "dark") {
      window.localStorage.setItem("theme", "light");
      changeTheme("light");
      console.log(theme);
    } else {
      window.localStorage.setItem("theme", "dark");
      changeTheme("dark");
    }
  };

  useEffect(() => {
    const theme = window.localStorage.getItem("theme");
    if (theme === "dark") {
      document.body.classList.remove("light");
      changeThemeSwitcher(
        <i style={{ color: "white" }} className="fa fa-sun-o"></i>
      );
    } else {
      changeThemeSwitcher(<i className="fa fa-moon-o"></i>);
      document.body.classList.add("light");
    }
  }, [theme]);
  return (
    <BrowserRouter>
      <div className="layout">
        <button className="switchThemeButton" onClick={() => switchTheme()}>
          {themeSwitcher}
        </button>

        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/post_description/:id/:post_name"
          component={PostDescription}
        />
        {/* <Route exact path="/admin/auth" component={Auth} />
        <Route exact path="/admin/new_post" component={NewPost} />
        <Route exact path="/admin/dashboard" component={Dashboard} /> */}
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/tags/:tagName" component={TagsPostList} />
      </div>
    </BrowserRouter>
  );
};

export default Layout;
